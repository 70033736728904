.tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    flex-wrap: wrap;

    &>button {
        margin: 0 10px;
        margin-bottom: 5px;
    }
}
