.selector__control {
    &.selector__control--is-focused {
        border-color:  #27ae60;
        box-shadow: none;

        &:hover {
            border-color:  #27ae60;
        }
    }
}

.selector__value-container {
    &>div:last-child {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.selector__option{
    &.selector__option--is-focused{
        background-color: #27ae6020;
    }

    &.selector__option--is-selected{
        background-color: #27ae60;
    }
}
